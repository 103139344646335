import React from "react";

import Section from "../../../HOC/Section";

import blogImage1 from "../../../assets/img/blog1.webp";
import blogImage2 from "../../../assets/img/blog2.webp";
import blogImage3 from "../../../assets/img/blog3.webp";

const Blog = () => {
  return (
    <Section id="blog">
      <div className="container pt-2 pb-5">
        <div className="section-header pt-5 pb-5 text-center">
          <h3 className="section-title">
            <span> </span>Blog
          </h3>
          <h6 className="section-subtitle mr-auto ml-auto"></h6>
        </div>
        <div className="section-content">
          <div className="row">
            <div className="col-lg-4 mb-3">
              <div className="card rounded-0">
                <img src={blogImage1} className="card-img-top" alt="Blog 1" />
                <div className="card-body">
                  <h5 className="card-title">ASANSÖRLERDE RENK ETİKETLERİ</h5>
                  <p className="card-text" style={{ height: "auto" }}>
                    Yıllık kontrolü yapılan her asansöre uzman kontrol
                    mühendisleri tarafından kırmızı, sarı, mavi veya yeşil
                    renkli zemin üzerine, gerekli açıklamaların siyah renkli
                    olarak yapıldığı bir bilgi etiketi iliştirilir. Bu etiketler
                    asansörlerinizin can ve mal güvenliğine uygun olup
                    olmadığını anlayabilmeniz için kabin içerisindeki
                    kullanıcıların rahatlıkla görebileceği bir şekilde kumanda
                    butonunun etrafına ve ana durakta yer alan kumanda düğme
                    panelinin yanında bulunmaktadır. <br />
                    <br />
                    Eğer asansörünüzde bu etiketlerden bulunmuyor ise en kısa
                    zamanda apartman yöneticiniz veya apartman yönetim firmanız
                    ile iletişime geçip asansörlerinize periyodik
                    kontrol/muayene yapılmasını talep edebilirsiniz.
                    <br />
                    <br />
                    <span style={{ color: "red", fontWeight: "bold" }}>
                      Kırmızı Etiket;
                    </span>{" "}
                    asansörün kullanım açısından yüksek riskli olduğunu belirten
                    etiket türüdür. <br />
                    <br />
                    <span style={{ color: "#E4C00E", fontWeight: "bold" }}>
                      Sarı Etiket;
                    </span>{" "}
                    kusurlu seviyede risk oluşturan asansörlere yapıştırılan
                    etikettir. <br />
                    <br />
                    <span style={{ color: "blue", fontWeight: "bold" }}>
                      Mavi Etiket;
                    </span>{" "}
                    hafif riskli olarak değerlendirebilir. Kullanımında herhangi
                    bir kısıtlama yoktur. <br />
                    <br />
                    <span style={{ color: "#1ACD38", fontWeight: "bold" }}>
                      Yeşil Etiket;
                    </span>{" "}
                    kontrolü yapılan asansörün kullanım açısından herhangi bir
                    risk taşımadığı ve güvenli olduğunu ifade eder. <br />
                  </p>
                  <a
                    href="https://www.instagram.com/eftalia.diamond.beauty/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="btn btn-primary rounded-0 mr-2"
                  >
                    Daha Fazla Bilgi
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="card rounded-0">
                <img src={blogImage2} className="card-img-top" alt="Blog 2" />
                <div className="card-body">
                  <h5 className="card-title">MUAYENE NEDİR?</h5>
                  <p className="card-text" style={{ height: "auto" }}>
                    24 Haziran 2015 tarihinde yayınlanan “Asansör İşletme, Bakım
                    ve Periyodik Kontrol Yönetmeliği” gereğince asansörün
                    hizmete alınmasını müteakip iki yıl sonra, devamında yılda
                    en az bir kere olmak üzere yapılması gereken kontrollerdir.
                    Asansör Bakım ve İşletme Yönetmeliği’nin amacı, insanların
                    ve/veya yüklerin taşınmasında kullanılan asansörlerin
                    hizmete alındıktan sonra çevrenin, insanların ve canlıların
                    sağlık ve güvenliğini tehdit etmeyecek şekilde
                    kullanımlarını sağlamak üzere; işletilmesi, bakımı ve yıllık
                    kontrolleri için uyulması gereken kuralları belirlemektir.
                    <br />
                    <br />
                    <b>
                      Asansörlerime periyodik muayene nasıl yaptırırım?
                    </b>{" "}
                    Bağlı bulunduğunuz belediyenin yetkilendirdiği A tipi
                    muayene kuruluşuna başvurarak asansörlerinize periyodik
                    kontrol/muayene yapabilirsiniz.
                    <br />
                    <br />
                    <b>
                      Asansörlerimin bakımı zaten periyodik olarak yapılıyor,
                      periyodik kontrol/muayene yaptırmama gerek var mı?
                    </b>{" "}
                    Asansör bakımı ve periyodik kontrol birbirinden farklı
                    hizmetlerdir. Asansör bakımları, aylık olarak asansörün
                    genel durumunun değerlendirilmesi, eksikliklerinin
                    giderilmesi amacı ile yapılan bir faaliyettir. Periyodik
                    kontrol ise, asansörün can ve mal güvenliği açısından
                    kullanıma uygunluğu ile ilgili yapılan ayrıntılı muayenedir.
                  </p>
                  <a
                    href="https://www.instagram.com/eftalia.diamond.beauty/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="btn btn-primary rounded-0 mr-2"
                  >
                    Daha Fazla Bilgi
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="card rounded-0">
                <img src={blogImage3} className="card-img-top" alt="Blog 3" />
                <div className="card-body">
                  <h5 className="card-title">
                    ASANSÖRLERDE KUMANDA SİSTEMLERİ?
                  </h5>
                  <p className="card-text" style={{ height: "auto" }}>
                    Asansör Kumanda sistemleri genel olarak asansörlerin
                    elektrikli donanımını kontrol eden motor hareket emirlerini
                    düzenleyerek gerekli durumlarda durmayı sağlayan
                    parçalardır. Kumanda sistemlerinin gerekli özellikleri
                    standartlar belirler.
                    <br />
                    <b>Temel Standart Gereksinimleri</b>
                    <br />
                    <br />
                    Genel yeterlilikler olarak asansör kumandaları EN 81-20’ye
                    göre aşağıdaki yeteneklere haiz olmalıdır; <br />
                    <ul>
                      <li>
                        Asansörleri en alt ve en üst katlara gönderebilmelidir.
                      </li>
                      <li>
                        Asansörlerin dinamik testleri kuyuya girmeden
                        yapılabilmelidir.
                      </li>
                      <li>
                        Asansör kat çağrıları kumandadan iptal edilebilir
                        olmalıdır.
                      </li>
                      <li>
                        Asansörlerde en az; kabinin hareket yönü, kat kapı
                        açılma bölgeleri ve asansörün hızı doğrudan izlenebilir
                        olmalıdır.
                      </li>
                    </ul>
                    Kat Çağrı Yöntemlerine Göre Kumanda Tipleri <br />
                    <ul>
                      <li>Konvansiyonel Kumanda</li>
                      <li>Kattan Çağrı Atama Sistemi</li>
                    </ul>
                    <br />
                    Ayrıca iki kumanda türünün birlikte kullanıldığı hibrit
                    çözümler de mevcuttur. Kattan çağrı atama sistemleri yüksek
                    yoğunluklu binalarda tercih edilir. Özellikle ofis binaları
                    bu tip kullanım için uygun olur.
                  </p>
                  <a
                    href="https://www.instagram.com/eftalia.diamond.beauty/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="btn btn-primary rounded-0 mr-2"
                  >
                    Daha Fazla Bilgi
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Blog;
