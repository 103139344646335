import React from "react";

import Section from "../../../HOC/Section";
import i1 from "./ServiceDetails/1.webp";
import i2 from "./ServiceDetails/2.webp";
import i3 from "./ServiceDetails/3.webp";
import i4 from "./ServiceDetails/4.webp";
import i5 from "./ServiceDetails/5.webp";

const Service = () => {
  return (
    <Section id="services">
      <div className="container pt-2 pb-5">
        <div className="section-header pt-5 pb-5 text-center">
          <h3 className="section-title">
            <span>Verilen </span>Hizmetler
          </h3>
          <h6 className="section-subtitle mr-auto ml-auto"></h6>
        </div>
        <div className="section-content">
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="service-box d-flex">
                <div class="card">
                  <img
                    src={i1}
                    class="card-img-top"
                    style={{ width: "%100", height: "300px" }}
                    alt="..."
                  ></img>
                  <div class="card-body">
                    <h5
                      className="service-title"
                      style={{ color: "#2f266f", fontWeight: "bold" }}
                    >
                      Projelendirme
                    </h5>
                    <p class="card-text">
                      Asansör uygulama projeleri Bolkar Asansör bünyesinde
                      bulunan ilgili proje hizmet mühendislik birimi tarafından
                      yapılır. Firmamız bünyesinde SMM ve Asansör avan proje
                      çizimi yetki belgelerine sahip elektrik ve makine
                      mühendisleri bulunmaktadır. Asansör projeleriniz tescil
                      dosyası ile birlikte tüm yönetmeliklere uygun şekilde
                      hazırlanmaktadır. Bolkar Asansör projelendirme konusunda
                      sürekli yerel ve ulusal yönetmelikleri takip ederek
                      müşterilerimizin koşul ve şartlarına uygun çalışmalar
                      sunmaktadır.
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="service-box d-flex">
                <div class="card">
                  <img
                    src={i2}
                    class="card-img-top"
                    style={{ width: "%100", height: "300px" }}
                    alt="..."
                  ></img>
                  <div class="card-body">
                    <h5
                      className="service-title"
                      style={{ color: "#2f266f", fontWeight: "bold" }}
                    >
                      İmalat & Montaj
                    </h5>
                    <p class="card-text">
                      Bolkar Asansör, imalatını yaptığımız asansör komponent
                      malzemelerini firmamızda bulunan kalite kontrol
                      mühendisleri tarafından işin başlaması ve
                      sonlandırılmasına kadar takipçisi olup kusursuz işleyişi
                      sürdürmek için çaba sarf etmektedir. Montaj ve
                      teslimatımız için belirlenen süreyi iyi değerlendirir ve
                      montajın her evresinde gerekli kontrol ve denemeleri
                      yaparak sonraki aşamalarda karşılaşılabilecek aksaklıklara
                      engel olunur. Şantiyemiz nerede olursa olsun, asansör
                      montajı aşaması gereken sıklıklarda yetkili
                      mühendislerimiz tarafından kontrol edilir; varsa işi
                      aksatan sebepler incelenir ve bu sebeplerin zamanında
                      bertaraf edilmesi için gereken her türlü önlem alınır. 
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-6">
              <div className="service-box d-flex">
                <div class="card">
                  <img
                    src={i4}
                    class="card-img-top"
                    style={{ width: "%100", height: "300px" }}
                    alt="..."
                  ></img>
                  <div class="card-body">
                    <h5
                      className="service-title"
                      style={{ color: "#2f266f", fontWeight: "bold" }}
                    >
                      Bakım,Onarım ve Servis
                    </h5>
                    <p class="card-text">
                      Asansörlerin aylık periyodik bakımı asansörlerinizin
                      sağlığı açısından önem taşımaktadır. Periyodik kontrolleri
                      düzenli yapılan asansörlerde; kullanım ömrü uzamakta,
                      güvenlik açısından riskler ortaya çıkmamakta, asansör
                      verimli ve konforlu bir şekilde çalışmaktadır. Periyodik
                      bakımlarda; asansörlerinin mekanik ve elektronik aksamları
                      kontrol edilir. Arızaya sebep olacak faktörler ve sorunlu
                      parçalar ilgili kişilere bilgi verilerek
                      değiştirilir ve/veya arıza giderilir. Asansörlerin bakımı;
                      Asansör Bakım, İşletme ve Periyodik Kontrol Yönetmeliği’
                      ne uygun olarak yapılmaktadır. Tarafımızca tesis edilen
                      asansörler hariç; periyodik bakım sözleşmesi öncesi
                      firmamız tarafından asansörün detaylı testleri yapılarak,
                      güvenliği tehdit eden unsurlar ilgili kişi/ kişilere rapor
                      olarak bildirilmektedir. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="service-box d-flex">
                <div class="card">
                  <img
                    src={i5}
                    class="card-img-top"
                    style={{ width: "%100", height: "300px" }}
                    alt="..."
                  ></img>
                  <div class="card-body">
                    <h5
                      className="service-title"
                      style={{ color: "#2f266f", fontWeight: "bold" }}
                    >
                      Revizyon ve Modernizasyon
                    </h5>
                    <p class="card-text">
                      Asansörleriniz yaşlanmaya başladıysa, bina kullanım
                      amacınız değiştiyse, sürekli arıza ile karşılaşıyorsanız,
                      estetiğe önem veriyorsanız, asansörünüzü güvenli ve
                      konforlu bir hale getirmenin çözümü revizyondur.
                      Asansörünüzün revizyonun da güvenlik başta olmak üzere;
                      enerji verimliliği, performansı ve ilgili standartların
                      tamamı dikkate alınmaktadır. Bolkar Asansör size en uygun
                      çözümleri sunmaktadır. Revizyona başlamadan önce;
                      tarafımızca yapılması gerekenlerin bilinmesi ve
                      netleştirilmesi amacı ile detaylı bir rapor
                      hazırlanmaktadır. Bu rapor doğrultusunda sizin
                      tercihlerinize bağlı kalarak asansörünüzün iyileştirme
                      sürecine başlamaktayız.
                      <br/>
                      <br/>
                      <br/>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </Section>
  );
};

export default Service;
