import React from 'react';
import Section from '../../../HOC/Section';

const mapMarker = () => {
  return (
    <Section id='map'>
      <div>
        <iframe
          title='mapMarker'
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d678.5605639578455!2d34.522226086542105!3d36.74966539294708!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x242c0d596d4f9f81%3A0xe24f3ddcd048d5f7!2sBolkar%20Asans%C3%B6r!5e0!3m2!1str!2str!4v1705340003908!5m2!1str!2str"
          width='100%'
          height='450'
          frameBorder='0'
          style={{ border: 0, marginBottom: '-7px' }}
          allowFullScreen
        />
      </div>
    </Section>
  );
};

export default mapMarker;
