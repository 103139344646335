import React from "react";

import Section from "../../../HOC/Section";
import aboutImage from "../../../assets/img/bolkarAbout.webp";

const about = () => {
  return (
    <Section id="about">
      <div className="container pt-2 pb-5">
        <div className="section-header pt-5 pb-5 text-center">
          <h3 className="section-title">
            <span>BOLKAR ASANSÖR</span>
          </h3>
        </div>
        <div className="section-content">
          <div className="row">
            <div className="col-md-12 col-lg-6 mb-3">
              <div className="aboutImage">
                <img
                  src={aboutImage}
                  alt="Erzurum Lazer Epilasyon"
                  title="Bolkar Asansör Bakım Onarım Montaj Hizmetleri"
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <h5 className="about-title">Hakkımızda</h5>
              <div className="about-description">
                <p style={{ color: "black" }}>
                  Misyonumuz, 20 yılı aşkın mesleki tecrübelerimizin üzerine siz
                  değerli müşterilerimizin istek ve dönüşlerini dikkate alarak
                  emin adımlarla "dünyaya açılan marka" olma ilkesi ile kaliteli
                  malzeme, kaliteli işçilik ve 7/24 Teknik desteği ile hizmet
                  vermekteyiz.Tek gayemiz en son teknoloji asansör ürünlerini
                  araştırıp,geliştirip siz değerli müşterilerimizin güvenli
                  asansör ihtiyacını karşılamak..
                </p>
                <h5 className="about-title" style={{ color: "black" }}></h5>
                <div className="about-description">
                  <p style={{ color: "black" }}>
                    Asansör imalatı sektöründe faaliyette bulunmak üzere 2008
                    yılında kurulmuş olan BOLKAR ASANSÖR ailesi olarak
                    müşterilerimizin ve tüm iş ortaklarımızın memnuniyet ve
                    güvenlerini ön planda tutmak, ürün-hizmet kalitemizi üst
                    düzeyde ilerletmek ilk hedefimizdir. Teknolojik gelişimi
                    yakından takip ederek küresel dünya pazarı karşısında yeni
                    çözümler üretmek ve sizler için en doğru yaklaşımları hayata
                    geçirmek amacıyla hareket etmekteyiz. Asansör üretimi ve
                    satışında Marmara Bölgesi’nde konumumuzu daha da
                    güçlendirerek markamızı ülkemizin tüm bölgelerinden talep
                    edilen ve güvenilen asansör markası haline getirmek için
                    çalışmalarımızı en üst düzeyde ilerletmekteyiz.
                  </p>
                </div>
                <h5 className="about-title" style={{ color: "black" }}></h5>
                <div className="about-description">
                  <p style={{ color: "black" }}>
                    Teknolojik verilerin ışığında malzeme kalitesini, makine
                    parkurunu ve yönetim organizasyonunu kalite politikasının
                    bir uzantısı olarak geliştiren BOLKAR ASANSÖR; yeni yatırım
                    perspektifi çerçevesinde 450 m2 imalat alanını 2013 yılında
                    900 m2'lik alana çıkartmış bulunmaktadır. Şuan da aktif
                    olarak fabrikamız ile firmamız sizlere daha iyi ve kaliteli
                    hizmet sunabilmek için çalışmalarına devam etmektedir.
                  </p>

                  <br />
                  <p style={{ color: "black" }}>
                    {" "}
                    Edindiğimiz tecrübelerimiz ve çalışanlarımızın eşsiz
                    mühendislik yetenekleri ile dünyanın önde gelen asansör
                    şirketlerinden biri haline gelmekteyiz. Asansörde
                    sürdürülebilirlik ilkesine dayanarak, kentleri ve binaları
                    daha akıllı, güvenilir ve yaşanacak en iyi yerler haline
                    getirirken, kentleşmenin zorluklarıyla mücadele ederek
                    yenilikçi çözümlere yatırım yapmaktayız.
                  </p>
                </div>

                <a
                  href="https://bolkarasansor.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="btn btn-primary rounded-0 mr-2"
                >
                  Daha Fazla Bilgi
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default about;
