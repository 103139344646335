import React from 'react';

import Section from '../../../HOC/Section';
import bgImage from '../../../assets/img/home_bg.jpg';
import Link from '../../UI/Link/Link';

const home = () => {
  return (
    <Section id='home'>
      <div>
        <div
          className='home-content p-5'
          style={{ backgroundImage: `url(${bgImage})` }}
        >
          <div className='intro container text-center text-light'>
            <h1 className='title'>BOLKAR ASANSÖR </h1>
            <h2 className='sub-title mb-4'>
              ASANSÖR BAKIM ONARIM MONTAJ HİZMETLERİ <br/><br/>
              "Firmamız, uzman ekibi ve hızlı montaj hizmetiyle güvenli asansör çözümleri sunar. Asansör sektöründe güvenlik ve kalite ihtiyaçlarını karşılayan tüm belgelere sahip firmamız ile garantili ve uzun ömürlü asansör çözümlerini tecrübe edebilirsiniz."
            </h2>
            <a href="https://wa.me/+905054308925" target="_blank" rel="noopener noreferrer" class='btn btn-success rounded-0 mr-2'>
              Whatsapp
            </a>
            <a href="tel: +905054308925" target="_blank" rel="noopener noreferrer" class='btn btn-primary rounded-0 mr-2'>
              Teklif Al
            </a>

            
          </div>
        </div>
      </div>
    </Section>
  );
};

export default home;
