import React from "react";
import Section from "../../../HOC/Section";

const Device = () => {
  return (
    <Section id="device">
      <div className="container pt-2 pb-5">
        <div className="section-header pt-5 pb-5 text-center">
          <h3 className="section-title">
            <span> </span>Ürünler
          </h3>
          <div className="section-content">
            <div className="row">
              <div className="col-md-12 col-lg-12 mb-12">
                <br />
                <ul class="list-group">
                  <li
                    class="list-group-item active"
                    aria-current="true"
                    style={{ backgroundColor: "#2f266f" }}
                  >
                    <b>Yolcu ve İnsan Asansörü</b>
                  </li>
                  <li class="list-group-item">
                    Yolcu ve insan asansörleri, yük taşıma kapasitelerine göre
                    320 kg’dan başlayarak 2500 kg’a kadar çeşitlilik gösteren
                    özellikle insan taşımak amacıyla kullanılan asansörlerdir.
                  </li>
                  <li class="list-group-item">
                  Yolcu asansörlerinin proje tasarımında; dekorasyon, kullanım
                    amacı, bina yüksekliği, taşıma kapasitesi, birçok detay ve
                    standartlar göz önüne alınarak hidrolik veya elektrik
                    (makine daireli veya makine dairesiz) tahrikli sistemler
                    uygulanmaktadır.
                  </li>

                  
                  
                </ul>
                <br />
                <ul class="list-group">
                  <li
                    class="list-group-item active"
                    aria-current="true"
                    style={{ backgroundColor: "#2f266f" }}
                  >
                    <b>Yük Asansörü</b>
                  </li>
                  <li class="list-group-item">
                           Yük asansörleri, yük taşıma kapasitelerine göre 630
                    kg’dan başlayarak 15.000 kg’a kadar çeşitlilik gösteren eşya
                    veya yük taşıma amacıyla kullanılan asansörlerdir.
                  </li>
                  <li class="list-group-item">
                      Yük asansörlerinin hızları, binanın yüksekliğine, yük
                    taşıma kapasitesine göre değişen hız çeşitliliğine sahiptir.
                  </li>
                </ul>

                <ul class="list-group">
                  <li
                    class="list-group-item active"
                    aria-current="true"
                    style={{ backgroundColor: "#2f266f" }}
                  >
                    <b>Engelli Asansörü</b>
                  </li>
                  <li class="list-group-item">
                    Birçok rahatsızlıktan dolayı binalarda katlara ulaşmakta
                    zorlanan kişiler için tasarlanmış asansörlerdir.
                  </li>
                  <li class="list-group-item">
                    EN 81-70 (Engelliler Dahil Yolcu Asansörleri İçin
                    Erişilebilirlik) standardına uygun, yanında refakatçı
                    olmaksızın engelli bireylerin kullanabilecekleri şekilde
                    özel tasarlanmış asansör çeşididir.
                  </li>
                </ul>

                <ul class="list-group">
                  <li
                    class="list-group-item active"
                    aria-current="true"
                    style={{ backgroundColor: "#2f266f" }}
                  >
                    <b>Sedye Asansörü</b>
                  </li>
                  <li class="list-group-item">
                  Sedye asansörleri, uluslararası standartlarca belirlenen ve kullanım amacına göre esas olarak sağlık tesislerinde kullanılmak üzere tasarlanmış asansörlerdir.
                  </li>
                  <li class="list-group-item">
                  Asansör sayısı, kapasitesi ve hızı; hastanelerin yatak sayısı dikkate alınarak,  yapılan trafik hesabı sonucu tespit edilmektedir
                  </li>
                </ul>

                <ul class="list-group">
                  <li
                    class="list-group-item active"
                    aria-current="true"
                    style={{ backgroundColor: "#2f266f" }}
                  >
                    <b>Araç Asansörü</b>
                  </li>
                  <li class="list-group-item">
                    Nüfus yoğunluğuyla orantılı olarak artan araç sayısı nedeniyle ortaya çıkan otopark sorununu azaltmak için bodrum katlarına veya bina üstlerine araç asansörleri yapılmaya başlanmıştır. Bu sayede araç asansörleriyle gereksiz alan kayıplarının önüne geçilebilmektedir.
                  </li>
                  <li class="list-group-item">
                     Araç asansörleri projesi aracın genişliği, uzunluğu, yüksekliği ve ağırlığına göre tasarlanmalıdır.
                  </li>
                </ul>

                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Device;
