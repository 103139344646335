import React from "react";

import Link from "../Link/Link";

const footer = () => {
  return (
    <footer className="">
      <div className="container text-light pt-5">
        <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-4 mb-5">
            <div className="footer-title">
              <h6>Hakkında</h6>
            </div>
            <div className="footer-content">
              <p style={{color:"white"}}>
                <small className="text">
                  BOLKAR ASANSÖR olarak deneyimini, tecrübesini ve kalitesini
                  bünyesinde barındırmakta, daha ileri seviyede müşteri
                  memnuniyeti, hizmet kalitesi, teknolojik gelişmeleri takip
                  alanında uzmanlaşmış teknik ve idari personeliyle geleceği ve
                  sektöre yatırımlar yapmaktayız. Asansör Bakımı, Asansör
                  Montajı, Asansör Tamiri, Asansör Revizyon. Sektöründe Siz
                  Değerli Müşterilerimize Uzman Çalışma Arkadaşlarımızla Hizmet
                  Vermekteyiz.
                </small>
              </p>
              <a
                href="https://www.instagram.com/bolkarasansor/"
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-primary rounded-0 mr-2"
              >
                Daha Fazla Bilgi
              </a>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-2 mb-5">
            <div className="footer-title">
              <h6>Bağlantılar</h6>
            </div>
            <div className="footer-content">
              <ul className="list-group quick-links">
                <li>
                  <Link target="home" offset={-120}>
                    Anasayfa
                  </Link>
                </li>
                <li>
                  <Link target="about">Hakkında</Link>
                </li>
                <li>
                  <Link target="services">Hizmetler</Link>
                </li>
                <li>
                  <Link target="blog">Blog</Link>
                </li>
                <li>
                  <Link target="contact">İletişim</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3 mb-5">
            <div className="footer-title">
              <h6>Çalışma Saatleri</h6>
            </div>
            <div className="footer-content">
              <table class="table table-striped">
                <tbody>
                  <tr
                    style={{ height: "2px", color: "white", fontSize: "small" }}
                  >
                    <td>Haftaiçi</td>
                    <td>09:00 - 19:00</td>
                  </tr>
                  <tr
                    style={{ height: "2px", color: "white", fontSize: "small" }}
                  >
                    <td>Cumartesi</td>
                    <td>09:00 - 19:00</td>
                  </tr>
                  <tr
                    style={{ height: "2px", color: "white", fontSize: "small" }}
                  >
                    <td>Pazar</td>
                    <td>Kapalı</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3 mb-5">
            <div className="footer-title">
              <h6>İletişim Bilgileri</h6>
            </div>
            <div className="footer-content">
              <p className="text">
                <small>
                  {" "}
                  <span style={{ color: "yellow" }}>Adres : </span> Merkez Mahallesi 52070 Sokak Hacıoğulları Apartmanı No:19/B <br/>
                  Mezitli / MERSİN
                </small>
              </p>
              <p className="text">
                <small>
                  <span style={{ color: "yellow" }}>Telefon : </span> 0505 430 89 25
                </small>
                <br/>
                <small>
                  <span style={{ color: "yellow" }}>Telefon : </span> 0532 475 67 12
                </small>
              </p>
              <p className="text">
                <small>
                  <span style={{ color: "yellow" }}>E-mail : </span>{" "}
                  mersinbolkarasansor@gmail.com
                </small>
              </p>
              <div className="social-media mt-4">
                <a
                  href="https://www.facebook.com/profile.php?id=61554137504334"
                  target="_blank"
                  className="text-light"
                >
                  <i className="fab fa-facebook-f mr-4" />
                </a>
                <a
                  href="https://wa.me/+905054308925"
                  target="_blank"
                  className="text-light"
                >
                  <i className="fab fa-whatsapp mr-4" />
                </a>
                <a
                  href="https://www.instagram.com/bolkarasansor/"
                  target="_blank"
                  className="text-light"
                >
                  <i className="fab fa-instagram mr-4" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-footer pt-3 pb-3 text-center">
        <small>© All Right Reserved. Design By Berati Şahin</small>
      </div>
    </footer>
  );
};

export default footer;
